const expressLaundryTypes = [
  {
    name: "Wash And Fold",
    imgUrl:
      "https://cdn-icons-png.flaticon.com/512/6553/6553745.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22",
    price: 104,
  },
  {
    name: "Wash And Iron",
    imgUrl:
      "https://cdn-icons-png.flaticon.com/512/2647/2647321.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22",
    price: 149,
  },
];
export default expressLaundryTypes;
