export const Shoes = [
    {
        id: "Sports Shoes",
        price: 249,
        identifier: "Sport"
    }, {
        id: 2,
        type: "Casual Shoes",
        price: 299,
        identifier: "Casual"
 
    }, {
        id: 3,
        type: "Leather Shoes",
        price: 399,
        identifier: "Leather"
 
    },{
        id: 4,
        type: "Formal Shoes",
        price: 200,
        identifier: "Formal"
 
    }

]