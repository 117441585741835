import React from 'react';

const services = [
  {
    title: 'Washing',
    description: 'High-quality washing services for all types of garments. We use the best detergents and fabric softeners to ensure your clothes feel fresh and clean.',
    icon: '🧼',
  },
  {
    title: 'Dry Cleaning',
    description: 'Professional dry cleaning for delicate fabrics like silk, wool, and more. Our eco-friendly process ensures your garments remain in pristine condition.',
    icon: '👔',
  },
  {
    title: 'Ironing',
    description: 'Get perfectly pressed clothes with our ironing service. We handle each piece with care to remove wrinkles and make your clothes look brand new.',
    icon: '🧺',
  },
  {
    title: 'Pickup & Delivery',
    description: 'Convenient pickup and delivery service for your laundry needs. We ensure timely service to fit your schedule.',
    icon: '🚚',
  },
  {
    title: 'Folding',
    description: 'We provide professional folding services for your freshly cleaned clothes, making it easy to store and organize.',
    icon: '🧵',
  },
  {
    title: 'Stain Removal',
    description: 'Expert stain removal services for tough stains on all types of fabrics. We use safe and effective methods to restore your garments.',
    icon: '🧽',
  },
];
const ServiceCard = ({ title, description, icon }) => (
  <div className="bg-white shadow-md rounded-lg z-10 overflow-hidden p-6 text-center hover:shadow-lg transition duration-300 transform hover:-translate-y-1 ">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-bold text-red-400 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Services = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-red-400 mb-8">Our Laundry Services</h2>
        <p className="text-center text-gray-600 mb-12">
          We provide a range of laundry services to keep your clothes fresh, clean, and well cared for. Discover what we offer below!
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {services.map((service, index) => (
            <ServiceCard 
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
