
import React from 'react';

const ContactUsCard = () => {
  return (
    <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-10">
      <div className="bg-red-400 p-6">
        <h2 className="text-2xl font-bold text-white">Contact Us</h2>
        <p className="text-white mt-2">We would love to hear from you!</p>
      </div>
      <div className="p-6">
        <div className="flex items-center mb-4">
          <svg className="w-6 h-6 text-red-400 mr-3" fill="currentColor" viewBox="0 0 20 20">
            <path d="M2.003 5.884l8.717 4.49a1 1 0 00.94 0l8.717-4.49A2 2 0 0018 4H2a2 2 0 00-.997 1.884zM18 8.118l-8 4.118-8-4.118V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <span className="text-gray-800">itlaundrywala@gmail.com</span>
        </div>
        <div className="flex items-center mb-4">
          <svg className="w-6 h-6 text-red-400 mr-3" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M6.671 7.5a1 1 0 011.5-.866 9.972 9.972 0 011.328.767 9.972 9.972 0 011.328-.767 1 1 0 111 1.732 7.977 7.977 0 00-1.96 1.133 7.977 7.977 0 00-1.96-1.133 1 1 0 01-.536-.866zm7.95-4.383A12.97 12.97 0 0010 1.056 12.97 12.97 0 006.379 3.117a1 1 0 11-1.05-1.742A14.97 14.97 0 0110 .056a14.97 14.97 0 014.671 1.32 1 1 0 11-1.05 1.742z" clipRule="evenodd" />
            <path fillRule="evenodd" d="M4.697 4.553a1 1 0 011.053 1.741 7.968 7.968 0 00-1.96 1.133 7.968 7.968 0 00-1.96-1.133 1 1 0 11-.536-.866A9.972 9.972 0 016.671 7.5a1 1 0 011.5-.866 7.977 7.977 0 00-3.03 1.54 7.977 7.977 0 003.03 1.54 1 1 0 111-1.732 9.972 9.972 0 01-1.328-.767 9.972 9.972 0 01-1.328.767 1 1 0 01-.536.866zm10.606 8.144a1 1 0 011.136-1.764A9.972 9.972 0 0018 15.5a9.972 9.972 0 001.96-1.133 1 1 0 011.053 1.741A7.977 7.977 0 0120 17.144a7.977 7.977 0 01-1.96-1.133 1 1 0 01.536-.866zM14 13.5a7.977 7.977 0 01-3.03-1.54 7.977 7.977 0 013.03-1.54 1 1 0 111 1.732 9.972 9.972 0 001.328.767 9.972 9.972 0 001.328-.767 1 1 0 111-1.732A7.968 7.968 0 0116.303 12.5a7.968 7.968 0 011.96-1.133 1 1 0 11-1.053-1.741A9.972 9.972 0 0014 13.5zM10 14.5a1 1 0 01-1-1v-3a1 1 0 012 0v3a1 1 0 01-1 1zm1 0a1 1 0 011-1v-3a1 1 0 012 0v3a1 1 0 01-1 1zM10 11a1 1 0 01-1-1V7a1 1 0 012 0v3a1 1 0 01-1 1zm1 0a1 1 0 011-1V7a1 1 0 012 0v3a1 1 0 01-1 1z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-800">+91 8982827878</span>
        </div>
        <div className="flex items-center">
          <svg className="w-6 h-6 text-red-400 mr-3" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M3.707 7.293a1 1 0 010 1.414L2.414 10H17a1 1 0 110 2H2.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
          <span className="text-gray-800">Shree Krishana House, Near Matoshree Food Court, Mukai Nagar, Hinjewadi Phase 1</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsCard;
