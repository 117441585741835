import { Smile, Gauge, Leaf, Clock } from 'lucide-react';

// export const BASE_URL="http://localhost:4000/api"
// export const BASE_URL="http://65.0.212.19:4000/api"
export const BASE_URL="https://itlaundrywala.com/api"

export const NUMBER_OF_CLOTH_TYPE=[1,2,3,4,5]
export const MonthNames=['Jan','Feb','March','April','May','June','July','Aug','Sep','Oct','Nov','Dec']
export const PICKUP_TIME=["Select","07.00 AM - 10.00 AM","12.00 PM - 3.00 PM","06.00 PM - 9.00 PM"]
export const COUNTRY_CODE=['+91']
export const DryCleaningButtonOptions=["MEN","WOMEN","SHOES"]
export const houseHoldButtonsOptions=["HOUSEHOLD"]
export const pressHoldButtonsOptions=["MEN","WOMEN"]
export const expressLaundryButtonOptions=["MEN","WOMEN","PRESS","SHOES","KG"]
export const products = [
    { id: 1, title: "Wash &  Fold", price: "69", type:"kg", description: "48-72 hrs",identifier:"customerDetails",imgUrl:"https://cdn-icons-png.flaticon.com/512/6553/6553745.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22" },
    { id: 2, title: "Wash & Iron", price: "99", type:"kg", description: "48-72 hrs" ,identifier:"customerDetails",imgUrl:"https://cdn-icons-png.flaticon.com/512/2647/2647321.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22"},
    { id: 3, title: "Dry Cleaning", price: "79", type:"piece", description: "48-72 hrs",identifier:"dryCleaning",imgUrl:"https://cdn-icons-png.flaticon.com/512/995/995021.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22" },
    { id: 4, title: "Express Laundry", price: "104",  type:"kg",description: "48-72 hrs",identifier:"expressLaundry",imgUrl:"https://cdn-icons-png.flaticon.com/512/8119/8119693.png?uid=R131201878&ga=GA1.1.1814783212.1704270810&%22" },
    
  ];

  export const howItWorksSSteps = [
    { icon: "./gif/online-order.gif", title: "Book Order", step: 1 },
    { icon: "./gif/pickup-truck.gif", title: "Pickup at Doorstep", step: 2 },
    { icon:"./gif/laundry.gif", title: "Process", step: 3 },
    { icon: "./gif/door-delivery.gif", title: "Delivery at Doorstep", step: 4 },
  ];



  export const promises = [
    {
      icon: Smile,
      title: "Free pick & drop",
      description: "We provide Free pick & drop facility at your doorstep."
    },
    {
      icon: Gauge,
      title: "Affordable",
      description: "Our pricing policy is fair & transparent. What you see is what you pay!"
    },
    {
      icon: Leaf,
      title: "Quality assurance",
      description: "We strive to give best laundry & dry cleaning experience."
    },
    {
      icon: Clock,
      title: "Convenience",
      description: "Book your service in less than 20 seconds."
    }
  ];

  export const accordionItems = [
    {
      title: 'What services do you offer?',
      content: 'We offer a wide range of laundry and dry cleaning services, including wash & fold, shirt service, alterations, and more. Our team is equipped to handle all your fabric care needs with expertise and care.'
    },
    {
      title: 'How does the pick-up and delivery service work?',
      content: 'Our convenient pick-up and delivery service allows you to schedule a time for us to collect your laundry from your doorstep. Once cleaned, we\'ll return your items at a time that suits you. You can easily schedule these services through our website or mobile app.'
    },
    {
      title: 'What are your turnaround times?',
      content: 'Our standard turnaround time is 48 hours for most services. However, we also offer express services for when you need your items back sooner. Same-day service is available for orders placed before 9 AM, and next-day service for orders placed before 5 PM.'
    },
    {
      title:"How clothes are measured?",
      content:"Clothes are measured at your doorstep using electronic weighing scale."
    },{
      title:"Can you deliver in 24 hours?",
      content:"For all express orders delivery is done in 24 hours."
    }
    
  ];