const ProductCard = ({ title, price, description,setOpen,setCurrentPage,identifier,imgUrl,type }) => (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col  items-center ">
      <img src={imgUrl} className="w-full h-48 md:h-40 bg-gray-200 rounded-md mb-4"></img>
      <div className="text-lg font-semibold ">{title}</div>
      <div className="text-lg font-semibold mb-2  mt-auto">{`Starts @ ₹${price}/${type}` }</div>
      <div className="text-gray-600 mb-1">{description}</div>

      <button onClick={()=>{setOpen(true)
        setCurrentPage(identifier)
      }} className="mt-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors">
        Start Order
      </button>
    </div>
  );

  export default ProductCard